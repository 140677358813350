import {useAPI} from "./useAPI";

export function useReservation() {
  const {api} = useAPI();

  const getServicesForDate = async (branch_id: number, date: string): Promise<Array<any>> => {
    return new Promise<Array<any>>((resolve)=> {
      setTimeout(() => {
        resolve([
            {
                'id': 1,
                'name': 'Almoço',
                'times': ['12:00', '12:30', '13:00', '13:30']
            },
            {
                'id': 2,
                'name': 'Jantar',
                'times': ['12:00', '12:30', '13:00', '13:30']
            }
        ]);
      }, 1 * 1000);
    });
  }

  return {
    getServicesForDate
  };
}
