import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputMask from "react-input-mask";
import clsx from "clsx";
import { useReservation } from "../../../hooks/useReservation";
import moment from "moment";
import { BusinessBranchModel } from "../../../models/BusinessModel";
import Loading from "../../../components/Loading";
import { ReservationModel } from "../../../models/Reservation";
import { useHelper } from "../../../hooks/useHelper";
import { CustomerModel } from "../../../models/CustomerModel";
import { useCustomer } from "../../../hooks/useCustomer";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const CommentSchema = Yup.object().shape({
    name: Yup.string()
        .required('Nome é obrigatório.'),
    phone: Yup.string()
        .required('Telefone é obrigatório.'),
    email: Yup.string()
        .email('Email inválido.')
        .required('Email é obrigatório.'),
    birth_date: Yup.string()
        .required('Data de nascimento é obrigatório.'),
    reason: Yup.string(),
    comment: Yup.string(),
    customer_id: Yup.number()
});

interface Props {
    onSubmit: (data: any) => void,
    business_branch: BusinessBranchModel,
    reservation?: ReservationModel
}
const ReservationDataForm: FC<Props> = ({ onSubmit, business_branch, reservation }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { getServicesForDate } = useReservation();
    const [loading, setLoading] = useState(true);
    const [services, setServices] = useState<Array<any>>([]);
    const [reservationTime, setReservationTime] = useState<undefined | string>((reservation) ? reservation.time : undefined);
    const [reservationServiceId, setReservationServiceId] = useState<undefined | number>((reservation) ? reservation.service_id : undefined);
    const helper = useHelper();
    const [showAllFields, setShowAllFields] = useState(false);

    // TODO: Get from database
    const minReservationPersons = 2;
    const maxReservationPersons = 5;

    const [reservationDate, setReservationDate] = useState((reservation) ? reservation.date : moment((new Date())).format("YYYY-MM-DD"));
    const [reservationPersonsNumber, setReservationPersonsNumber] = useState((reservation) ? reservation.person_number : minReservationPersons);
    const [phone, setPhone] = useState((reservation) ? reservation.phone : "");
    const [customer, setCustomer] = useState<CustomerModel | undefined>(undefined);
    const customerApi = useCustomer();

    const formik = useFormik({
        initialValues: {
            name: (reservation) ? reservation.name : "",
            email: (reservation) ? reservation.email : "",
            phone: (reservation) ? reservation.phone : "",
            comment: (reservation) ? reservation.comment : "",
            birth_date: (reservation) ? reservation.birth_date : "",
            reason: (reservation) ? reservation.reason : "1",
            customer_id: undefined
        },
        validationSchema: CommentSchema,
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: (values: any, { setStatus, setSubmitting }) => {
            if (!reservationTime) {
                alert("Escolha o horário da reserva.");
                return;
            }

            onSubmit({
                person_number: reservationPersonsNumber,
                date: reservationDate,
                service_id: reservationServiceId,
                time: reservationTime,
                ...values,
            });
        },
    });

    useEffect(() => {
        const formikPhone = helper.onlyNumbers(formik.values.phone);

        if (formikPhone !== phone && formikPhone.length >= 11) {
            setPhone(formikPhone);
            setShowAllFields(false);
            
            if (!executeRecaptcha) {
                return;
            }
            setLoading(true);
            executeRecaptcha('customer').then((token) => {
                return customerApi.find(formikPhone, token).then((resp) => {
                    formik.setFieldValue("name", resp?.name);
                    formik.setFieldValue("email", resp?.email);
                    formik.setFieldValue("birth_date", resp?.birth_date);
                    formik.setFieldValue("customer_id", resp?.id);
                    setLoading(false);
                    setShowAllFields(true);
                }, () => {
                    setLoading(false);
                    formik.setFieldValue("name", "");
                    formik.setFieldValue("email", "");
                    formik.setFieldValue("birth_date", "");
                    formik.setFieldValue("customer_id", "");
                    setShowAllFields(true);
                });
            }, () => {
                setLoading(false);
                formik.setFieldValue("name", "");
                formik.setFieldValue("email", "");
                formik.setFieldValue("birth_date", "");
                formik.setFieldValue("customer_id", "");
                setShowAllFields(true);
            });
        }
    }, [formik.values]);

    useEffect(() => {
        setReservationServiceId(undefined);
        setReservationTime(undefined);
        getServicesForDate(business_branch.id, reservationDate)
            .then((services) => {
                setServices(services);
                setLoading(false);
            }).catch(() => { setLoading(false); });
    }, [reservationDate]);

    return (
        <>
            {loading && (<Loading fullscreen={true} />)}
            <form onSubmit={formik.handleSubmit} noValidate={true}>
                <div className="card-body">
                    <div className="row border border-dashed border-gray-300 rounded-3 px-3 py-7 mb-3">
                        <div className="col-6 d-flex align-items-center">
                            <div className="svg-icon svg-icon-2 svg-icon-success me-3 text-muted">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-people" viewBox="0 0 16 16">
                                    <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
                                </svg>
                            </div>
                            <span className="text-gray-700 fw-bold fs-6">Pessoas</span>
                        </div>
                        <div className="col-6 d-flex align-items-center justify-content-end">
                            <div className="row">
                                <div className="position-relative w-100px"
                                    data-kt-dialer="true"
                                    data-kt-dialer-min="2"
                                    data-kt-dialer-max="50"
                                    data-kt-dialer-step="1">
                                    <button
                                        type="button"
                                        className={clsx("btn btn-icon text-gray-500 position-absolute translate-middle-y top-50 start-0", {
                                            "disabled": reservationPersonsNumber <= minReservationPersons
                                        })}
                                        onClick={() => {
                                            let decreseadPersonsNumber = reservationPersonsNumber - 1;
                                            if (decreseadPersonsNumber >= minReservationPersons)
                                                setReservationPersonsNumber(decreseadPersonsNumber);
                                        }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-dash-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                                        </svg>
                                    </button>
                                    <input
                                        type="text"
                                        className="form-control form-control-solid border-0 text-center p-0 text-gray-700 fs-5 fw-bolder bg-white"
                                        readOnly={true}
                                        value={reservationPersonsNumber} />
                                    <button
                                        type="button"
                                        className={clsx("btn btn-icon text-gray-500 position-absolute translate-middle-y top-50 end-0", {
                                            "disabled": reservationPersonsNumber >= maxReservationPersons
                                        })}
                                        onClick={() => {
                                            let increseadPersonsNumber = reservationPersonsNumber + 1;
                                            if (increseadPersonsNumber <= maxReservationPersons)
                                                setReservationPersonsNumber(increseadPersonsNumber);
                                        }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row border border-dashed border-gray-300 rounded-3 px-3 py-7 mb-3">
                        <div className="col-6 d-flex align-items-center">
                            <div className="svg-icon svg-icon-2 svg-icon-success me-3 text-muted">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar-week" viewBox="0 0 16 16">
                                    <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"></path>
                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"></path>
                                </svg>
                            </div>
                            <span className="text-gray-700 fw-bold fs-6">Data</span>
                        </div>
                        <div className="col-6" style={{ 'textAlign': 'right' }}>
                            <label className="datepicker">
                                <div
                                    className="btn btn-sm d-flex align-items-center p-0 justify-content-end">
                                    <div className="text-gray-700 fw-bold fs-6">{moment(reservationDate).format('D MMMM YYYY')}</div>
                                    <span className="svg-icon svg-icon-3 ms-3 me-0 text-gray-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                        </svg>
                                    </span>
                                </div>
                                <input
                                    type="date"
                                    value={moment(reservationDate).format('YYYY-MM-DD')}
                                    onChange={(e) => {
                                        setReservationDate(e.target.value);
                                    }}
                                />
                            </label>
                        </div>
                    </div>
                    <div className="row mt-7">
                        <div className="col-12">
                            <span className="text-gray-500 fs-6 d-block fw-bold">Selecione o horário:</span>
                        </div>
                    </div>
                    {services?.map((service: any) => (
                        <div className="row mt-3" key={service.id}>
                            <span className="text-gray-700 fw-bold fs-6">{service.name}</span>
                            <div className="d-flex flex-nowrap hover-scroll-x py-2">
                                <div className="d-flex flex-stack gap-3">
                                    {service.times.map((time: string) => (
                                        <button
                                            type="button"
                                            key={`${service.id}-${time}`}
                                            className={clsx("btn btn-color-primary btn-active-primary border w-100", {
                                                'active': reservationServiceId === service.id && reservationTime === time
                                            })}
                                            onClick={() => {
                                                setReservationServiceId(service.id);
                                                setReservationTime(time);
                                            }}
                                        >{time}</button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
                <div className="separator separator-dotted border-2 w-75 m-auto"></div>
                <label className="d-block">
                    <span className="section-ticket dots">
                        <span className="bg-light"></span>
                        <span className="bg-light"></span>
                    </span>
                </label>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 mb-5">
                            <span className="text-gray-700 fw-bold fs-6 d-block mb-2 required">Telefone</span>
                            <InputMask
                                mask={'(99) 99999-9999'}
                                type="text"
                                placeholder="Digite seu telefone"
                                {...formik.getFieldProps('phone')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    { 'is-invalid': formik.touched.phone && formik.errors.phone },
                                    {
                                        'is-valid': formik.touched.phone && !formik.errors.phone,
                                    }
                                )}
                            />
                            {formik.touched.phone && formik.errors.phone && (
                                <div className='fv-plugins-message-container'>
                                    <span role='alert'>{formik.errors.phone}</span>
                                </div>
                            )}
                        </div>
                        {showAllFields && (
                            <>
                                <div className="col-12 mb-5">
                                    <span className="text-gray-700 fw-bold fs-6 d-block mb-2 required">Nome</span>
                                    <input
                                        type="email"
                                        placeholder="Digite seu nome"
                                        maxLength={255}
                                        {...formik.getFieldProps('name')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            { 'is-invalid': formik.touched.name && formik.errors.name },
                                            {
                                                'is-valid': formik.touched.name && !formik.errors.name,
                                            }
                                        )}
                                    />
                                    {formik.touched.name && formik.errors.name && (
                                        <div className='fv-plugins-message-container'>
                                            <span role='alert'>{formik.errors.name}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 mb-5">
                                    <span className="text-gray-700 fw-bold fs-6 d-block mb-2 required">Email</span>
                                    <input
                                        type="email"
                                        placeholder="Digite seu email"
                                        maxLength={255}
                                        {...formik.getFieldProps('email')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            { 'is-invalid': formik.touched.email && formik.errors.email },
                                            {
                                                'is-valid': formik.touched.email && !formik.errors.email,
                                            }
                                        )}
                                    />
                                    {formik.touched.email && formik.errors.email && (
                                        <div className='fv-plugins-message-container'>
                                            <span role='alert'>{formik.errors.email}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 mb-5">
                                    <span className="text-gray-700 fw-bold fs-6 d-block mb-2 required">Data de Nascimento</span>
                                    <InputMask
                                        mask={'99/99/9999'}
                                        type="text"
                                        placeholder="Data de nascimento"
                                        {...formik.getFieldProps('birth_date')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            { 'is-invalid': formik.touched.birth_date && formik.errors.birth_date },
                                            {
                                                'is-valid': formik.touched.birth_date && !formik.errors.birth_date,
                                            }
                                        )}
                                    />
                                    {formik.touched.birth_date && formik.errors.birth_date && (
                                        <div className='fv-plugins-message-container'>
                                            <span role='alert'>{formik.errors.birth_date}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 mb-5">
                                    <span className="text-gray-700 fw-bold fs-6 d-block mb-2">Motivo</span>
                                    <select
                                        className="form-select form-select-solid form-control-lg"
                                        {...formik.getFieldProps('reason')}
                                    >
                                        <option value="1">Aniversário</option>
                                        <option value="2">Aniversário de Casamento</option>
                                        <option value="3">Bodas</option>
                                        <option value="4">Batizado</option>
                                        <option value="5">Casamento</option>
                                        <option value="6">Confraternização da Empresa</option>
                                        <option value="7">Outros</option>
                                    </select>
                                </div>
                                <div className="col-12">
                                    <span className="text-gray-700 fw-bold fs-6 d-block mb-2">Observação</span>
                                    <textarea
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder="Escreva aqui sua observação"
                                        {...formik.getFieldProps('comment')}
                                    ></textarea>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="separator separator-dotted border-2 w-75 m-auto"></div>
                <label className="d-block">
                    <span className="section-ticket dots">
                        <span className="bg-light"></span>
                        <span className="bg-light"></span>
                    </span>
                </label>
                <div className="card-body">
                    <div className="d-grid gap-2">
                        <button type="submit" className="btn btn-lg btn-primary py-5 shadow">
                            Avançar
                            <span className="svg-icon svg-icon-2 ms-1 me-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default ReservationDataForm;